#header {
    height: 70px;
}

.navbar {
    height: 70px;
    padding: 0 55px 0 55px !important;
    /*border-bottom: 1px solid var(--color-solid-semitransparent);*/
    background-color: var(--color-solid) /*var(--color-neutral)*/;
    z-index: 1;
}

.navbar-brand {
    margin-right: 0 !important;
    padding: 0 !important;
    color: var(--color-background) !important;
}

.navbar-text {
    color: var(--color-background) !important;
    margin-right: 10px;
}

.navbar img {
    box-shadow: none;
}