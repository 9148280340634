.page-container span, .page-container h2 {
    text-align: center;
}

.page-container h1 {
    margin-bottom: 2rem;
    font-size: 7em;
    display: inline;
}

.page-container h2 {
    font-weight: 400;
    margin-bottom: 3rem;
    white-space: pre-wrap;
}

.page-container a.button {
    margin-bottom: 5rem;
    padding: 1rem 2rem;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 8px;
    color: var(--color-background);
    background-color: rgb(0, 157, 223);
}

.page-container a:hover, .inner-demo-tooltip a:hover {
    text-decoration: none;
    background-color: rgb(0, 125, 178);
}

.publication {
    width: 100%;
    display: flex;
}

.publication > div {
    width: 50%;
}

.page-container img {
    float: right;
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#description {
    display: flex;
    align-items: center;
}

#description p {
    margin-bottom: 0;
    margin-left: 10px;
}

.tooltip.show {
    opacity: 1 !important;
}

.arrow-demo-tooltip {
    margin-left: 10px;
}

.bs-tooltip-auto .arrow::before {
    border-right-color: var(--color-background) !important;
}

.inner-demo-tooltip {
    position: relative !important;
    max-width: 330px !important;
    margin-left: 10px;
    padding: .8rem !important;
    color: var(--color-solid) !important;
    background-color: var(--color-background) !important;
    text-align: left !important;
}

#tooltip-close {
    float: right;
    cursor: pointer;
}
