/* Theming */
:root {
  /* Background color */
  --color-background: rgb(240, 240, 240);
  --color-background-semitransparent: rgba(240, 240, 240, 0.5);
  --color-background-light: rgb(255, 255, 255);
  --color-background-dark: rgb(229, 229, 229);
  /* Font color */
  --color-solid: rgb(33, 37, 41);
  --color-solid-semitransparent: rgba(33, 37, 41, 0.5);
  --color-solid-nearlyopaque: rgba(33, 37, 41, 0.8);
  /* Primary color */
  --color-primary: rgb(2, 143, 117);
  --color-primary-pair-first: rgb(2, 143, 117);
  --color-primary-pair-second: rgb(197, 234, 227);
  /* Neutral color */
  --color-neutral: rgb(168, 168, 168);
  --color-neutral-dimmed: rgb(204, 204, 204);
  /* Highlight color */
  --color-secondary: rgb(239, 108, 0);
  --color-ternary: rgb(41, 140, 249);
  /* Shadow color */
  --color-shadow: rgba(0, 0, 0, 0.2);
  /* Christmas animation */
  --main-red: #EA385C;
  --main-red-darkened: #B70529;
  --main-gold-lt: #E7B75C;
  --main-gold-lt-darkened: #B48429;
  --main-blue-lt: #386FB1;
  --main-blue-lt-darkened: #053C7E;
  --main-blue-dk: #28527C;
  --main-blue-dk-darkened: #001F49;
  --main-gold-dk: #B28947;
  --main-gold-dk-darkened: #7F5614;
  --main-g1: 0s;
  --main-g2: 0.4s;
  --main-g3: 0.8s;
}

/* Full-screen distribution of header and remaining page-content */
#app {
  display: flex;
  flex-flow: column;
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
  padding: inherit;
  overflow: hidden;
}

/*.page-content {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding-top: 5px;
}*/

.page-content {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.page-container {
  width: 70%;
  height: calc(100% - 40px); /* Footer has 40px height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow-y: auto;
  padding: 3rem 0;
}

.svg-responsive {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* Custom checkboxes */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  width: 19px;
  height: 8px;
  background-color: var(--color-neutral);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  left: 0;
  bottom: -2px;
  background-color: var(--color-solid);
  transition: left 450ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.switch input:checked + .slider {
  background-color: var(--color-primary-pair-second);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--color-primary);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(7px);
  -ms-transform: translateX(7px);
  transform: translateX(7px);
  background-color: var(--color-primary-pair-first);
}

.switch .label {
  padding-left: 20px;
}

/*Custom radio buttons*/

input[type=radio] {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  outline: none;
  background-color: var(--color-background);
  box-shadow: 0 0 0 2px var(--color-neutral);
}

input[type=radio]:before {
  content: '';
  display: block;
  width: 50%;
  height: 50%;
  margin: 25% 25%;
  border-radius: 50%;
}

input[type=radio]:checked {
  box-shadow: 0 0 0 2px var(--color-primary-pair-first);
}

input[type=radio]:checked:before {
  background: var(--color-primary-pair-first);
}

/* Imprint and DSGVO stylings */

#impressum-container {
  margin: 2rem 6rem;
  text-align: left;
  overflow-y: scroll;
}

#impressum-container hr {
  border-top: 1px solid var(--color-solid-semitransparent);
}

#impressum-container h4 {
  font-weight: 400;
  font-size: 2.125rem;
  text-align: center;
}

#impressum-container h5 {
  font-weight: 400;
  font-size: 1.5rem;
}

#impressum-container h6 {
  font-size: 1.25rem;
}

#impressum-container a {
  color: var(--color-primary);
}

#impressum-container a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

#impressum-container strong {
  font-weight: 600;
}
